import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, Typography } from '@mui/material'
import React from 'react'

import { PillButton } from '../../Components'

interface SubscribeFormProps {
  onClose?: () => void
  open?: boolean
}

export const SubscribeForm: React.FC<SubscribeFormProps> = (props) => {
  const { open = false, onClose } = props

  return (
    <Dialog aria-labelledby="form-dialog-title" disableScrollLock={true} onClose={onClose} open={open}>
      <DialogTitle>
        <Typography variant="h3">Additional Web3 Wallets</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          You found the secret list! Here are the wallets XYO is currently testing for optimal compatibility. All of them are Web3 but we want to make
          sure your favorite works perfectly!
          <ul>
            <li>
              <Link href="https://atomicwallet.io/" target="_blank">
                Atomic
              </Link>
            </li>
            <li>
              <Link href="https://flarewallet.io/" target="_blank">
                Flare
              </Link>
            </li>
            <li>
              <Link href="https://guarda.com/" target="_blank">
                Guarda
              </Link>
            </li>
            <li>
              <Link href="https://www.myetherwallet.com/" target="_blank">
                MyEtherWallet
              </Link>
            </li>
            <li>
              <Link href="https://ownrwallet.com/" target="_blank">
                Ownr
              </Link>
            </li>
            <li>
              <Link href="https://www.jaxx.io/" target="_blank">
                Jaxx
              </Link>
            </li>
            <li>
              <Link href="https://www.coinomi.com/en/" target="_blank">
                Coinomi
              </Link>
            </li>
          </ul>
          Also under consideration:
          <br />
          <Link href="https://www.samsung.com/global/galaxy/apps/samsung-blockchain/" target="_blank">
            Samsung
          </Link>
          ,{' '}
          <Link href="https://electrum.org/" target="_blank">
            Electrum
          </Link>
          ,{' '}
          <Link href="https://klever.io/" target="_blank">
            Klever
          </Link>
          ,{' '}
          <Link href="https://uphold.com/en-us/" target="_blank">
            Uphold
          </Link>
          ,{' '}
          <Link href="https://www.blockchain.com/wallet" target="_blank">
            Blockchain
          </Link>
          ,{' '}
          <Link href="https://celsius.network/" target="_blank">
            Celsius
          </Link>
        </DialogContentText>
        <DialogActions>
          <PillButton color="primary" onClick={onClose} variant="contained">
            Close
          </PillButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
