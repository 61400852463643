import { Box, Grid, GridProps, Typography } from '@mui/material'
import React from 'react'

import { PillButton } from '../../Components'
import pragueImage from './img/prague.png'

export const Intro: React.FC<GridProps> = (props) => {
  return (
    <Grid container {...props}>
      <Grid xs={12} lg={6} item>
        <img alt="Prague" data-aos="fade-up" src={pragueImage} width="100%" />
      </Grid>
      <Grid xs={12} lg={6} item data-aos="fade-left">
        <Typography style={{ margin: 15 }} variant="body1">
          Your geolocation data has a wealth of untapped potential. While services tied to geolocation have been improved and expanded immensely since
          the days of mere GPS navigation, most systems still neglect the core concept of dataism. They don&apos;t leverage the immense human network
          to its fullest.
        </Typography>
        <Typography style={{ margin: 15 }} variant="body1">
          The geolocation data your users contribute should do more for you, and for them, than provide basic statistics and analytics. It should be
          data that your users can interact with directly that hooks them and keeps them coming back. XYO World helps you to retain users and
          encourages them to improve your data. Use it to add incentives to your user experience, and use the data collected to improve even more.
        </Typography>
        <Typography style={{ margin: 15 }} variant="body1">
          XYO World is open-source, open-data, free to use, powered by blockchain, and cross-platform. See what it can do for you and your users.
        </Typography>
        <Box display="flex" justifyContent="center" marginTop="20px">
          <PillButton color="primary" disabled variant="contained">
            view&nbsp;sample&nbsp;data
          </PillButton>
        </Box>
      </Grid>
    </Grid>
  )
}
