import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography } from '@mui/material'
import React from 'react'

interface ThanksProps {
  onClose?: () => void
  open?: boolean
}

export const SpecialThanks: React.FC<ThanksProps> = (props) => {
  const { open = false, onClose } = props

  return (
    <Dialog aria-labelledby="form-dialog-title" disableScrollLock={true} onClose={onClose} open={open}>
      <DialogTitle id="form-dialog-title">
        <Typography style={{ fontSize: '2rem', fontWeight: 'bold' }}>Special Thanks</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6">Videographers</Typography>
        <Typography>
          XYO World would like to thank the talented videographers whose work was used in making the compilation videos used on our homepage.
        </Typography>
        <Box margin={3}>
          <Typography>
            <Link href="https://pixabay.com/users/anwaroptin-14811183/" target="_blank">
              Anwar Optin
            </Link>
            ,&nbsp;
            <Link href="https://pixabay.com/users/darhah-16395490/" target="_blank">
              Дархан Жолдубаев
            </Link>
            ,&nbsp;
            <Link href="https://pixabay.com/users/teefarm-199315/" target="_blank">
              TeeFarm
            </Link>
            ,&nbsp;
            <Link href="https://pixabay.com/users/carl_watermark-2053931/" target="_blank">
              Quod Sail
            </Link>
            ,&nbsp;
            <Link href="https://pixabay.com/users/bellergy-1846871/" target="_blank">
              RC Bellergy
            </Link>
            ,&nbsp;
            <Link href="https://pixabay.com/users/caelan-12802865/" target="_blank">
              Caelan Kelley
            </Link>
            ,&nbsp;
            <Link href="https://pixabay.com/users/xat-ch-12531001/" target="_blank">
              Xavier Turpain
            </Link>
            ,&nbsp;
            <Link href="https://pixabay.com/users/max_bbb-8327066/" target="_blank">
              Max Berube
            </Link>
            ,&nbsp;
            <Link href="https://pixabay.com/users/christianbodhi-9869182/" target="_blank">
              Christian Bodhi
            </Link>
            ,&nbsp;
            <Link href="https://pixabay.com/users/nomad_nation_videos-16211452" target="_blank">
              Nomad Nation
            </Link>
            ,&nbsp;
            <Link href="https://pixabay.com/users/louis-de-funes-9076487/" target="_blank">
              Louis de Funes
            </Link>
            ,&nbsp;
            <Link href="https://pixabay.com/users/mabelamber-1377835/" target="_blank">
              Mabel Amber
            </Link>
            ,&nbsp;
            <Link href="https://pixabay.com/users/luc_toutsimplement-7803492/" target="_blank">
              Luc Greiner
            </Link>
          </Typography>
        </Box>
        <DialogActions>
          <Link component="button" onClick={onClose}>
            close
          </Link>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
