import { Typography } from '@mui/material'
import { FlexCol, FlexRow } from '@xylabs/sdk-react'

import { PillButton } from '../../Components'

export const FooterCTA: React.FC = () => {
  return (
    <FlexCol width="100%">
      <Typography data-aos="fade-up" data-aos-delay="350" variant="h4">
        Want to learn more about XYO?
      </Typography>
      <FlexRow flexWrap="wrap">
        {/* Desktop */}
        <FlexRow sx={{ display: { md: 'flex', xs: 'none' } }} data-aos="fade-left" data-aos-delay="350" padding={2}>
          <PillButton color="primary" href="https://xyo.network/network/" target="_blank" variant="contained">
            xyo protocol
          </PillButton>
        </FlexRow>
        <FlexRow sx={{ display: { md: 'flex', xs: 'none' } }} data-aos="fade-right" data-aos-delay="350" padding={2}>
          <PillButton color="primary" href="https://xyo.network/" target="_blank" variant="contained">
            xyo foundation
          </PillButton>
        </FlexRow>
        {/* Mobile */}
        <FlexRow sx={{ display: { md: 'none' } }} data-aos="fade-up" data-aos-delay="450" padding={2}>
          <PillButton color="primary" href="https://xyo.network/network/" target="_blank" variant="contained">
            xyo protocol
          </PillButton>
        </FlexRow>
        <FlexRow sx={{ display: { md: 'none' } }} data-aos="fade-up" data-aos-delay="550" padding={2}>
          <PillButton color="primary" href="https://xyo.network/" target="_blank" variant="contained">
            xyo foundation
          </PillButton>
        </FlexRow>
      </FlexRow>
    </FlexCol>
  )
}
