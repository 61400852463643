import { FlexBoxProps, FlexCol } from '@xylabs/sdk-react'
import React from 'react'

import { Navbar } from '../Components'

interface Props extends FlexBoxProps {
  transparent?: boolean
}

export const BasePage: React.FC<Props> = ({ children, transparent = false, ...props }) => {
  return (
    <FlexCol alignItems="stretch" {...props}>
      <Navbar transparent={transparent} />
      {children}
    </FlexCol>
  )
}
