import { enableProfileLogging } from '@xyo-network/sdk-xyoworld-js'
import { render } from 'react-dom'

import { App } from './App'
import * as serviceWorker from './serviceWorker'

if (document.location.hostname === 'localhost') {
  enableProfileLogging(true)
}

render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
