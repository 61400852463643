import { createStyles, makeStyles } from '@mui/styles'

/* A scale microinteraction that does not cause displacement. */
/* This is not supported on Firefox */

export const hoverize = (hover = 1.1, hoverTime = 0.3) => {
  return makeStyles(() =>
    createStyles({
      hover: {
        '&:hover': {
          transform: `scale(${hover})`,
        },
        transitionDuration: `${hoverTime}s`,
        transitionProperty: 'transform',
        transitionTimingFunction: 'ease-in-out',
      },
    }),
  )().hover
}
