import { Grid } from '@mui/material'
import React from 'react'

import { DevCard } from './Card'
import cardOneImage from './img/card1.svg'
import cardTwoImage from './img/card2.svg'
import cardThreeImage from './img/card3.svg'

export const Cards: React.FC = () => {
  return (
    <Grid container spacing={4}>
      <Grid data-aos="fade-down" data-aos-duration="1000" item xs={12} md={4}>
        <DevCard
          action="Learn how COIN uses XYO World"
          alt="Incentivization"
          caption="Bring your users onboard with customization, staking rewards, an established currency, and more. Get them engaged the minute they download your app. Give them a fully realized world to explore, their own real world, and let them influence it."
          image={cardOneImage}
          subtitle="Engage your users quickly with unique UX."
          title="Incentivization"
        />
      </Grid>
      <Grid data-aos="fade-down" data-aos-delay="200" data-aos-duration="1000" item xs={12} md={4}>
        <DevCard
          action="Visualize XYO World engagement"
          alt="Retention"
          caption="Ensure long-term engagement when users stake. Keep the process fun by using their stake to benefit them in your apps. Allow them to customize their geotokens, win against other users, or any other use you can invent for a geotoken. The possibilities are endless."
          image={cardTwoImage}
          subtitle="Geospatial data, customization, and  the human element."
          title="Retention"
        />
      </Grid>
      <Grid data-aos="fade-down" data-aos-delay="400" data-aos-duration="1000" item xs={12} md={4}>
        <DevCard
          action="Check out our developer resources"
          alt="Integration"
          caption="XYO World integration is lightweight, flexible, and simple. An open source SDK and our API make it fast. Build your own structure, use XYO World however you like, and your users will join XYO World users all over the globe in geospatial staking for anyone."
          image={cardThreeImage}
          subtitle="Tools to run it smoothly."
          title="Integration"
        />
      </Grid>
    </Grid>
  )
}
