import { FlexCol } from '@xylabs/sdk-react'
import React from 'react'

import { Footer } from '../../Components'
import { BasePage } from '../BasePage'
import Guidelines1 from './img/guidelines1.jpg'
import Guidelines2 from './img/guidelines2.jpg'
import Guidelines3 from './img/guidelines3.jpg'
import Guidelines4 from './img/guidelines4.jpg'
import Guidelines5 from './img/guidelines5.jpg'

export const AdPolicyPage: React.FC = () => {
  return (
    <BasePage>
      <FlexCol marginY={4}>
        <img alt="XYO World Ad Guidelines 1" src={Guidelines1} width="100%" />
        <img alt="XYO World Ad Guidelines 2" src={Guidelines2} width="100%" />
        <img alt="XYO World Ad Guidelines 3" src={Guidelines3} width="100%" />
        <img alt="XYO World Ad Guidelines 4" src={Guidelines4} width="100%" />
        <img alt="XYO World Ad Guidelines 5" src={Guidelines5} width="100%" />
      </FlexCol>
      <Footer />
    </BasePage>
  )
}
