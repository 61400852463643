import { FlexRow } from '@xylabs/sdk-react'
import React from 'react'

import { appUrl } from '../../appUrl'
import { FadeBlock } from './FadeBlock'
import squareHiddenValley from './img/square-hidden_valley.jpg'
import squareSanDiego from './img/square-san_diego.jpg'
import squareSanFransisco from './img/square-san_fransisco.jpg'

export const FadeBlocks: React.FC = () => {
  return (
    <FlexRow flexWrap="wrap" alignItems="stretch">
      <FadeBlock
        margin={2}
        caption="Stake based on tangible data. Claim your part of your own world and digitally control and monetize it.
              This is staking for everyone."
        hoverText="Stake real places"
        image={`url(${squareSanDiego})`}
        linkText="Learn how it works"
        target="_self"
        url="/start"
      />
      <FadeBlock
        margin={2}
        caption="No one has exclusive rights to your world. Staking is shared by using a
              parent-child NFT system, and no portion of XYO World is ever off limits."
        hoverText="Infinite virtual real estate"
        image={`url(${squareHiddenValley})`}
        linkText="Get your geotokens"
        target="_blank"
        url={appUrl('auctions')}
      />
      <FadeBlock
        margin={2}
        caption="XYO Network is strengthened with absolute data from XYO World, and the possibilities are endless. Learn more about where XYO World is headed."
        hoverText="The Future of XYO"
        image={`url(${squareSanFransisco})`}
        linkText="Product roadmap"
        target="_self"
        url="/roadmap"
      />
    </FlexRow>
  )
}
