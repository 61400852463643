import { FlexBoxProps, FlexRow } from '@xylabs/sdk-react'
import React from 'react'

import angleHexImage from './img/hex_mountain.svg'

export const HexBar: React.FC<FlexBoxProps> = (props) => {
  return (
    <FlexRow
      height={420}
      style={{
        backgroundImage: `url(${angleHexImage})`,
        backgroundSize: 'cover',
      }}
      {...props}
    />
  )
}
