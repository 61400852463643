import { Container, Link, Typography } from '@mui/material'
import { FlexCol, FlexRow } from '@xylabs/sdk-react'
import React from 'react'

import { appUrl } from '../../appUrl'
import { Footer, PillButton } from '../../Components'
import { BasePage } from '../BasePage'
import { ImageFlexBox } from '../ImageFlexBox'
import { Cards } from './Cards'
import angleHexImage from './img/angle_hex.svg'
import highlightImage from './img/highlight-aqua.svg'
import heroImage from './img/joshua_tree.jpg'
import { Title } from './Title'

export const TokensPage: React.FC = () => {
  return (
    <BasePage transparent>
      <ImageFlexBox alt="Downtown Los Angeles skyline at sunset" src={heroImage}>
        <Title minHeight="70vh" />
      </ImageFlexBox>
      <FlexCol data-aos="fade-up" alignItems="stretch">
        <FlexRow
          height={300}
          position="relative"
          top={2}
          width="100%"
          style={{
            backgroundImage: `url(${angleHexImage})`,
            backgroundSize: 'cover',
          }}
        />
        <FlexCol background alignItems="stretch">
          <FlexRow
            minHeight="50vh"
            paddingBottom={2}
            position="relative"
            top={3}
            style={{
              backgroundImage: `url(${highlightImage})`,
              backgroundPosition: 'bottom',
              backgroundSize: 'cover',
              overflow: 'hidden',
            }}
          >
            <Container component={FlexCol}>
              <FlexCol data-aos="fade-left" marginBottom={15}>
                <Typography variant="h2">Need to get some XYO?</Typography>
                <Typography variant="h3">
                  If you want to participate in XYO World, <Link href={appUrl('rules')}>you&apos;ll need XYO</Link>!
                </Typography>
              </FlexCol>
              <Cards />
              <FlexCol>
                <Typography variant="h4">Need some help?</Typography>
                <Typography component={FlexRow} variant="h6" flexWrap="wrap">
                  <PillButton margin={2} href="https://medium.com/xyonetwork/buying-xyo-on-kucoin-949472948d4" target="_blank" variant="outlined">
                    Learn how to buy XYO on KuCoin
                  </PillButton>
                  <PillButton
                    margin={2}
                    href="https://medium.com/xyonetwork/setting-up-a-metamask-wallet-for-your-coin-withdrawals-1879c5e0cb4b"
                    target="_blank"
                    variant="outlined"
                  >
                    Learn how to set up a free MetaMask wallet
                  </PillButton>
                </Typography>
              </FlexCol>
            </Container>
          </FlexRow>
          <Footer />
        </FlexCol>
      </FlexCol>
    </BasePage>
  )
}
