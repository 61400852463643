import { Box, Card, CardActionArea, CardActions, CardContent, CardMedia, CardProps, Typography } from '@mui/material'

interface Props extends CardProps {
  alt: string
  caption: string
  cta: string
  image: string
  subtitle: string
  title: string
}

export const HolderCard: React.FC<Props> = ({ title, subtitle, cta, caption, image, alt, ...props }) => {
  return (
    <Card {...props} style={{ height: '100%' }}>
      <CardActionArea>
        <CardMedia alt={alt} component="img" image={image} />
        <CardContent
          sx={{
            opacity: { lg: '0%' },
            transitionDelay: { lg: '0.5s' },
            transitionDuration: { lg: '0.5s' },
            transitionProperty: { lg: 'all' },
            transitionTimingFunction: { lg: 'ease-in-out' },
          }}
        >
          <Typography variant="h5">{title}</Typography>
          <Typography variant="subtitle2">{subtitle}</Typography>
          <Typography style={{ marginTop: 25 }}>{caption}</Typography>
        </CardContent>
        <CardActions
          sx={{
            opacity: { lg: '0%' },
            transitionDelay: { lg: '0.5s' },
            transitionDuration: { lg: '0.5s' },
            transitionProperty: { lg: 'all' },
            transitionTimingFunction: { lg: 'ease-in-out' },
          }}
        >
          <Box marginTop={2}>
            <Typography variant="caption">{cta}</Typography>
          </Box>
        </CardActions>
      </CardActionArea>
    </Card>
  )
}
