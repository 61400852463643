import AccountBoxIcon from '@mui/icons-material/AccountBox'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import GavelIcon from '@mui/icons-material/Gavel'
import LanguageIcon from '@mui/icons-material/Language'
import LineStyleIcon from '@mui/icons-material/LineStyle'
import MapIcon from '@mui/icons-material/Map'
import { Box, IconButton, Toolbar, Typography } from '@mui/material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { AppBarEx, AppBarExProps, FlexRow } from '@xylabs/sdk-react'
import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { appUrl } from '../appUrl'
import logo from '../img/xyow_logo.svg'
import { PillButton } from './PillButton'

const PlansMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onMapClick = () => {
    window.location.href = appUrl('map')
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box marginX={1}>
      <PillButton color="inherit" variant="text" aria-controls="menu-one" aria-haspopup="true" onClick={handleClick}>
        Maps &amp; Data
      </PillButton>
      <Menu anchorEl={anchorEl} disableScrollLock={true} id="menu-one" keepMounted onClose={handleClose} open={Boolean(anchorEl)}>
        <MenuItem onClick={onMapClick}>
          <LanguageIcon /> &nbsp; Geotoken Global Map
        </MenuItem>
        <MenuItem disabled href="https://explore.xyo.network/#/heatmap" onClick={handleClose} target="_blank">
          <MapIcon /> &nbsp; XYO Heat Map
        </MenuItem>
        <MenuItem disabled href="https://explore.xyo.network/#/plugin/reports" onClick={handleClose} target="_blank">
          <FormatListNumberedIcon /> &nbsp; XYO Daily Leaderboard
        </MenuItem>
      </Menu>
    </Box>
  )
}

const AccountMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onMyHoldingsClick = () => {
    window.location.href = appUrl('entity')
  }

  const onAuctionsClick = () => {
    window.location.href = appUrl('uctions')
  }

  const onRulesClick = () => {
    window.location.href = appUrl('rules')
  }

  const onClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box marginX={1}>
      <PillButton color="inherit" variant="text" aria-controls="menu-two" aria-haspopup="true" onClick={handleClick}>
        Geotokens
      </PillButton>
      <Menu anchorEl={anchorEl} disableScrollLock={true} id="menu-two" keepMounted onClose={onClose} open={Boolean(anchorEl)}>
        <MenuItem onClick={onMyHoldingsClick}>
          <AccountBoxIcon /> &nbsp; My Holdings
        </MenuItem>
        <MenuItem onClick={onAuctionsClick}>
          <GavelIcon /> &nbsp; Auctions
        </MenuItem>
        <MenuItem onClick={onRulesClick}>
          <LineStyleIcon /> &nbsp; Rules &amp; Guidelines
        </MenuItem>
      </Menu>
    </Box>
  )
}

interface Props extends AppBarExProps {
  transparent?: boolean
}

export const Navbar: React.FC<Props> = ({ transparent = false, ...props }) => {
  return (
    <AppBarEx
      style={{ background: transparent ? 'transparent' : undefined, boxShadow: 'none', zIndex: 500 }}
      container="lg"
      contextToolbar={
        <Toolbar>
          <IconButton aria-label="menu" component={RouterLink} edge="start" to="/">
            <img alt="XYO World Logo" height={40} src={logo} width={40} />
          </IconButton>
          <Typography variant="h6" color="white">
            XYO World
          </Typography>
        </Toolbar>
      }
      systemToolbar={
        <Toolbar>
          <FlexRow color="white">
            <PlansMenu />
            <AccountMenu />
          </FlexRow>
        </Toolbar>
      }
      {...props}
    />
  )
}
