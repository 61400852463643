import { Container } from '@mui/material'
import { FlexBoxProps, FlexCol, FlexRow } from '@xylabs/sdk-react'
import React from 'react'

import { PillButton } from '../../Components'
import infobgImage from './img/infographic_background.svg'
import infographicImage from './img/use_case_infographic.svg'

export const Infographic: React.FC<FlexBoxProps> = (props) => {
  return (
    <FlexCol alignItems="stretch" {...props}>
      <FlexRow
        sx={{ display: { md: 'flex', xs: 'none' } }}
        style={{
          backgroundImage: `url(${infobgImage})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}
      >
        <Container>
          <img alt="Use Cases Infographic" data-aos="flip-up" data-aos-delay="500" data-aos-duration="1000" src={infographicImage} />
        </Container>
      </FlexRow>
      <FlexRow flexWrap="wrap" justifyContent="center" marginY={2}>
        <FlexRow>
          <PillButton color="primary" data-aos="fade-left" disabled variant="contained" marginX={1}>
            sample&nbsp;data
          </PillButton>
        </FlexRow>
        <FlexRow>
          <PillButton color="primary" data-aos="fade-left" data-aos-delay="200" data-aos-duration="1000" disabled variant="contained" marginX={1}>
            coin&nbsp;use&nbsp;case
          </PillButton>
        </FlexRow>
        <FlexRow>
          <PillButton color="primary" data-aos="fade-left" data-aos-delay="400" data-aos-duration="1000" disabled variant="contained" marginX={1}>
            see&nbsp;engagement
          </PillButton>
        </FlexRow>
        <FlexRow>
          <PillButton color="primary" data-aos="fade-left" data-aos-delay="600" data-aos-duration="1000" disabled variant="contained" marginX={1}>
            developer&nbsp;portal
          </PillButton>
        </FlexRow>
      </FlexRow>
    </FlexCol>
  )
}
