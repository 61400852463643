import { Box, Typography } from '@mui/material'
import { FlexCol, FlexRow } from '@xylabs/sdk-react'
import React from 'react'

import { appUrl } from '../../appUrl'
import { Footer, PillButton } from '../../Components'
import { BasePage } from '../BasePage'
import { ImageFlexBox } from '../ImageFlexBox'
import { Cards } from './Cards'
import { Coin } from './Coin'
import angleHexImage from './img/hex_wave.svg'
import heroImage from './img/redwood.jpg'
import waveHexImage from './img/spiral_wave.svg'
import { Title } from './Title'
import { Wallets } from './Wallets'

export const StartPage: React.FC = () => {
  return (
    <BasePage transparent>
      <ImageFlexBox alt="Redwood Forest National Park" src={heroImage}>
        <Title minHeight="70vh" />
      </ImageFlexBox>
      <FlexCol data-aos="fade-up" alignItems="stretch">
        <Box
          style={{
            backgroundImage: `url(${angleHexImage})`,
            backgroundSize: 'cover',
            height: 250,
            overflowY: 'hidden',
            width: '100%',
          }}
        />
        <Box bgcolor="white">
          <Box
            style={{
              backgroundColor: '#fff',
              backgroundImage: `url(${waveHexImage})`,
              minHeight: '50vh',
              overflow: 'hidden',
              paddingBottom: '3em',
              position: 'relative',
              top: 3,
            }}
          >
            <Box style={{ marginLeft: 'auto', marginRight: 'auto', padding: 0, width: '84vw' }}>
              <Box data-aos="fade-left" marginBottom={15}>
                <Typography variant="h2">XYO World 101</Typography>
                <Typography variant="h4">Let&apos;s learn the basics.</Typography>
              </Box>
              <Box>
                <Typography variant="h6">
                  WHAT IS XYO WORLD? A new way to imagine staking, XYO World allows its users to stake real places, with each participant actually
                  owning that piece of the map in the XYO World system, which is stored on the Ethereum blockchain as a non-fungible token (NFT).
                  These NFTs reproduce automatically, creating smaller, more granular location-based stake and ensuring that the supply of XYO World
                  digital real estate never runs out.
                </Typography>
              </Box>
              <FlexRow marginTop={1}>
                <PillButton
                  marginX={1}
                  color="primary"
                  variant="contained"
                  href="https://support.xy.company/hc/en-us/articles/360052580374"
                  target="_blank"
                >
                  start&nbsp;guide
                </PillButton>
                <PillButton color="primary" variant="contained" target="_self" href={appUrl('rules')} marginX={1}>
                  system&nbsp;rules
                </PillButton>
                <PillButton
                  marginX={1}
                  color="primary"
                  variant="contained"
                  href="https://support.xy.company/hc/en-us/sections/360008833073"
                  target="_blank"
                >
                  xyo&nbsp;support
                </PillButton>
              </FlexRow>
              <Cards marginY={2} />
              <Coin />
              <Wallets />
            </Box>
          </Box>
          <Footer />
        </Box>
      </FlexCol>
    </BasePage>
  )
}
