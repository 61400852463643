import { Box, Typography, useTheme } from '@mui/material'
import { FlexBoxProps, FlexCol } from '@xylabs/sdk-react'
import React, { useState } from 'react'

import { PillButton } from '../../Components'
import { SubscribeForm } from '../SubscribeForm'

export const Title: React.FC<FlexBoxProps> = (props) => {
  const theme = useTheme()
  const [subscribeFormOpen, setSubscribeFormOpen] = useState(false)
  return (
    <FlexCol {...props}>
      <Typography
        padding={4}
        color="white"
        component={Box}
        align="center"
        style={{ textShadow: '0em 0em .2em black' }}
        data-aos="fade-down"
        data-aos-duration="1200"
        data-aos-easing="ease"
        variant="h2"
      >
        Incorporate XYO &amp; build meaningful data
      </Typography>
      <Typography
        padding={4}
        component={Box}
        align="center"
        style={{ textShadow: '0em 0em .2em black' }}
        color={theme.palette.secondary.light}
        data-aos="fade"
        data-aos-duration="1200"
        data-aos-easing="ease"
        variant="h4"
      >
        XYO World is geolocation data with a human touch.
        <br />
        Open source, open data, and free to use.
      </Typography>
      <Box data-aos="fade" data-aos-duration="1200" data-aos-easing="ease" display="flex" justifyContent="center">
        <PillButton color="primary" onClick={() => setSubscribeFormOpen(true)} variant="contained">
          stay&nbsp;up&nbsp;to&nbsp;date
        </PillButton>
      </Box>
      <SubscribeForm onClose={() => setSubscribeFormOpen(false)} open={subscribeFormOpen} />
    </FlexCol>
  )
}
