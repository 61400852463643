import { Box, Container, Typography } from '@mui/material'
import { BasePage, FlexCol, FlexRow } from '@xylabs/sdk-react'
import React from 'react'

import { Footer } from '../../Components'
import { Cards } from './Cards'
import { Hero } from './Hero'
import { HexBar } from './HexBar'
import waveHexImage from './img/holder_hex_wave.svg'

export const HolderPage: React.FC = () => {
  return (
    <BasePage>
      <Hero />
      <FlexCol data-aos="fade-up" alignItems="stretch">
        <HexBar />
        <FlexCol bgcolor="white" alignItems="stretch">
          <FlexRow
            style={{
              backgroundColor: '#fff',
              backgroundImage: `url(${waveHexImage})`,
              backgroundPosition: 'bottom',
              backgroundSize: 'cover',
              minHeight: '50vh',
              overflow: 'hidden',
              paddingBottom: '3em',
              position: 'relative',
              top: 3,
            }}
          >
            <Container>
              <Box data-aos="fade-left" marginBottom={15}>
                <Typography variant="h2">Bring XYO into your world</Typography>
                <Typography variant="h3">utilize our technology the way you want</Typography>
              </Box>
              <Cards />
            </Container>
          </FlexRow>
          <Footer />
        </FlexCol>
      </FlexCol>
    </BasePage>
  )
}
