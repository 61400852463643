import { Grid, GridProps } from '@mui/material'
import React from 'react'

import { DevCard } from './Card'
import cardOneImage from './img/card1.svg'
import cardTwoImage from './img/card2.svg'
import cardThreeImage from './img/card3.svg'

export const Cards: React.FC<GridProps> = (props) => {
  return (
    <Grid container spacing={4} justifyContent="center" {...props}>
      <Grid md={4} xs={12} data-aos="fade-down" data-aos-duration="1000" item>
        <DevCard
          action="Visit XYO on KuCoin"
          alt="KuCoin"
          caption="XYO's long-time exchange parter is KuCoin, a trusted name in cryptocurrencies. Safe Bitcoin transactions start on KuCoin, and one out of four crypto holders worldwide uses KuCoin. We highly recommend them as our first choice in exchanges."
          image={cardOneImage}
          title="Our Partner Exchange"
          url="https://www.kucoin.com/"
        />
      </Grid>
      <Grid md={4} xs={12} data-aos="fade-down" data-aos-delay="200" data-aos-duration="1000" item>
        <DevCard
          action="Download COIN today!"
          alt="COIN"
          caption="Launched in early 2019, COIN is XY's hit mobile app enabling over 750,000 users to earn digital assets for validating geospatial location data when traveling, commuting, jogging or moving about the real world while interacting with each other and enjoying in-app challenges."
          image={cardTwoImage}
          title="Get Free XYO"
          url="https://coinapp.co/"
        />
      </Grid>
      <Grid md={4} xs={12} data-aos="fade-down" data-aos-delay="400" data-aos-duration="1000" item>
        <DevCard
          action="See where to find XYO"
          alt="CoinMarketCap"
          caption="Check out CoinMarketCap for a list of exchanges that trade XYO Tokens. Please note that XYO cannot assist you with third party exchanges, so if you need assistance with your purchase from an exchange, please contact the appropriate support team."
          image={cardThreeImage}
          title="Your Favorite Exchange"
          url="https://coinmarketcap.com/currencies/xyo/markets/"
        />
      </Grid>
    </Grid>
  )
}
