// eslint-disable-next-line import/no-internal-modules
import 'aos/dist/aos.css'

import { InvertibleThemeProvider, RedirectWithQuery } from '@xylabs/sdk-react'
// eslint-disable-next-line no-restricted-imports
import AOS from 'aos'
import React from 'react'
import { Helmet } from 'react-helmet'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Rollbar from 'rollbar'

import { AdPolicyPage, DevelopersPage, HolderPage, HomePage, RoadmapPage, StartPage, TokensPage } from './Pages'
import { themeOptions } from './theme'

AOS.init({
  mirror: true,
})

const getRollbarEnvironment = () => {
  switch (document.location.hostname) {
    case 'world.xyo.network':
      return 'production'
    case 'beta.world.xyo.network':
      return 'beta'
    default:
      return 'development'
  }
}

new Rollbar({
  accessToken: 'c7f99fad91ca4e1faecae09dc8bffd61',
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: getRollbarEnvironment(),
})

export const App: React.FC = () => {
  return (
    <InvertibleThemeProvider options={themeOptions}>
      <Helmet defaultTitle="XYO World" titleTemplate="%s | XYO World" />
      <Router>
        <Routes>
          <Route element={<HomePage />} path="/" />
          <Route element={<DevelopersPage />} path="/developers" />
          <Route element={<HolderPage />} path="/holder" />
          <Route element={<StartPage />} path="/start" />
          <Route element={<TokensPage />} path="/tokens" />
          <Route element={<RoadmapPage />} path="/roadmap" />
          <Route element={<AdPolicyPage />} path="/adpolicy" />
          <Route element={<RedirectWithQuery to="/" />} path="*" />
        </Routes>
      </Router>
    </InvertibleThemeProvider>
  )
}
