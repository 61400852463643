import { Container, Typography } from '@mui/material'
import { FlexCol, FlexRow } from '@xylabs/sdk-react'
import React from 'react'

import { Footer } from '../../Components'
import { BasePage } from '../BasePage'
import { ImageFlexBox } from '../ImageFlexBox'
import { Cards } from './Cards'
import angleHexImage from './img/angle_hex.svg'
import highlightImage from './img/highlight-aqua.svg'
import heroImage from './img/yosemite.jpg'
import { Infographic } from './Infographic'
import { Intro } from './Intro'
import { Title } from './Title'

export const DevelopersPage: React.FC = () => {
  return (
    <BasePage transparent>
      <ImageFlexBox alt="Downtown Los Angeles skyline at sunset" src={heroImage}>
        <Title minHeight="70vh" />
      </ImageFlexBox>
      <FlexCol data-aos="fade-up" alignItems="stretch">
        <FlexRow position="relative" top={2} style={{ backgroundImage: `url(${angleHexImage})`, backgroundSize: 'cover' }} height={300} />
        <FlexCol bgcolor="white" alignItems="stretch">
          <FlexCol
            bgcolor="white"
            minHeight="50vh"
            paddingY={4}
            position="relative"
            style={{
              backgroundImage: `url(${highlightImage})`,
              backgroundPosition: 'bottom',
              backgroundSize: 'cover',
            }}
          >
            <Container component={FlexCol}>
              <FlexCol data-aos="fade-left" marginBottom={15}>
                <Typography variant="h2">Supplemental geolocation data</Typography>
                <Typography variant="h3">provided by real humans who form visible consensus</Typography>
              </FlexCol>
              <Intro marginY={4} />
              <Cards />
            </Container>
          </FlexCol>
          <Infographic marginY={2} />
          <Footer />
        </FlexCol>
      </FlexCol>
    </BasePage>
  )
}
