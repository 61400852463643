import { Grid } from '@mui/material'
import React from 'react'

import { Box1, Box2, Box3, Box4, BoxBlank } from './Card'

export const Cards: React.FC = () => {
  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={4} data-aos="fade" data-aos-delay="400" data-aos-duration="1000" item>
        <Box2
          caption="Continuing upgrades for the user experience are planned. Since launch, these changes have included a private listing of one's own XYO World holdings and an enlarged view of quadtiles. We aim to make your experience better with every version."
          title="User experience upgrades"
        />
      </Grid>
      <Grid xs={12} md={4} data-aos="fade" data-aos-delay="1200" data-aos-duration="1000" item>
        <Box4
          caption="The possible uses for XYO geotokens are endless, and their potential has yet to be reached. Already added has been data associated with a geotoken, including a link and an image. New upgrades and integrations are on the horizon."
          title="Expanded uses for geotokens"
        />
      </Grid>
      <Grid xs={12} md={4} sx={{ mdDown: { display: 'none' } }} data-aos="fade" data-aos-delay="800" data-aos-duration="1000" item>
        <BoxBlank />
      </Grid>
      <Grid xs={12} md={4} sx={{ mdDown: { display: 'none' } }} data-aos="fade" data-aos-delay="200" data-aos-duration="1000" item>
        <BoxBlank />
      </Grid>
      <Grid xs={12} md={4} data-aos="fade" data-aos-delay="1000" data-aos-duration="1000" item>
        <Box3
          caption="We want to ensure your XYO geotokens have value now and into the future. Upcoming features will facilitate secondary market trading of geotokens, stimulating primary acquisitions and encouraging more enagement."
          title="Secondary market trading"
        />
      </Grid>
      <Grid xs={12} md={4} data-aos="fade" data-aos-delay="600" data-aos-duration="1000" item>
        <Box1
          caption="The first integrations with our closest partner, COIN by XY - The Persistent Company, have already been launched and are ready to be expanded. If you're a COIN app user, keep an eye out for big possibilities for you in XYO World."
          title="COIN integration"
        />
      </Grid>
    </Grid>
  )
}
