import { Card, CardActionArea, CardActions, CardContent, CardMedia, CardProps, Typography } from '@mui/material'
import React from 'react'

import { hoverize } from '../../hoverize'

interface Props extends CardProps {
  action: string
  alt: string
  caption: string
  image: string
  title: string
  url: string
}

export const DevCard: React.FC<Props> = ({ title, caption, image, alt, action, url, ...props }) => {
  return (
    <Card className={hoverize(1.025, 3)} {...props}>
      <CardActionArea href={url} style={{ height: '100%' }} target="_blank">
        <CardMedia alt={alt} component="img" image={image} />
        <CardContent style={{ height: '100%' }}>
          <Typography variant="h5">{title}</Typography>
          <Typography style={{ height: '20vh', marginTop: 25 }}>{caption}</Typography>
          <CardActions>
            <Typography variant="h6">{action}</Typography>
          </CardActions>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
