import { Grid } from '@mui/material'

import { Box1, Box2, Box3, Box4, BoxBlank } from './Card'

export const Cards2: React.FC = () => {
  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={4} sx={{ mdDown: { display: 'hidden' } }} data-aos="fade" data-aos-delay="800" data-aos-duration="1000" item>
        <BoxBlank />
      </Grid>
      <Grid xs={12} md={4} data-aos="fade" data-aos-delay="400" data-aos-duration="1000" item>
        <Box1
          caption="XYO World is for everyone, no matter where they’re from or where they want to explore. It aims to tear down walls and remove all limits. In this spirit, one of the first major innovations we plan to bring to the table is avatar-based geomining, which will allow an XYO World user to be anywhere they want to be, no matter where they are."
          title="Ranged avatars"
        />
      </Grid>
      <Grid xs={12} md={4} data-aos="fade" data-aos-delay="1200" data-aos-duration="1000" item>
        <Box2
          caption="Options to make XYO World more appealing to a wider audience, specifically gamified elements like achievements, competition, and interactive content, are being planned. What drives you when you play a game? Do you like to earn rewards, do you like to play against the game, or do you like to play against other players?"
          title="Expanded gamification"
        />
      </Grid>
      <Grid xs={12} md={4} data-aos="fade" data-aos-delay="1000" data-aos-duration="1000" item>
        <Box4
          caption="The expansion of XYO World requires more than just players. Some people are driven most by using a platform to build their own creative visions. Creators make a game even better, so tools to allow creators to add their content to XYO World are in the development stages. What would you build in your XYO World?"
          title="Tools for creators"
        />
      </Grid>
      <Grid xs={12} md={4} data-aos="fade" data-aos-delay="600" data-aos-duration="1000" item>
        <Box3
          caption="XYO World has the potential to become a powerful geographical and educational tool. We’re building ways to include the ability to geomine based on real geographic metadata, and even researching the inclusion of NASA’s open source data to make XYO World as high-level and accurate as possible. What could you learn from XYO World?"
          title="Interactive educational entertainment"
        />
      </Grid>
      <Grid xs={12} md={4} sx={{ mdDown: { display: 'hidden' } }} data-aos="fade" data-aos-delay="200" data-aos-duration="1000" item>
        <BoxBlank />
      </Grid>
    </Grid>
  )
}
