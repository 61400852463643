import { createStyles, makeStyles } from '@mui/styles'
import { FlexBoxProps, FlexRow } from '@xylabs/sdk-react'
import React from 'react'

const useStyles = makeStyles(() => {
  return createStyles({
    image: {
      '@media (orientation: landscape)': {
        minHeight: '100%',
        right: 0,
        width: '100%',
      },
      '@media (orientation: portrait)': {
        height: '100%',
        right: 0,
      },
      position: 'fixed',
      top: 0,
    },
  })
})

interface Props extends FlexBoxProps {
  alt?: string
  src?: string
  videoSrc?: string
}

export const ImageFlexBox: React.FC<Props> = ({ videoSrc, children, src, alt, ...props }) => {
  const classes = useStyles()
  return (
    <FlexRow position="relative" {...props}>
      <FlexRow position="fixed" minHeight="100vh">
        {videoSrc ? (
          <video
            autoPlay
            style={{
              bottom: 0,
              left: '50%',
              minHeight: '100%',
              minWidth: '100%',
              position: 'fixed',
              transform: 'translateX(-50%)',
            }}
            loop
            muted
          >
            <source src="/video/home-hero.mp4" type="video/mp4" />
          </video>
        ) : (
          <img alt={alt} src={src} className={classes.image} />
        )}
      </FlexRow>
      {children}
    </FlexRow>
  )
}
