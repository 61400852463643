import { colors, ThemeOptions } from '@mui/material'
const { cyan, teal } = colors

import { fontFamilyHeadings, fontFamilyPrimary } from './fontFamily'

export const themeOptions: ThemeOptions = {
  components: {
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
      styleOverrides: {
        root: {
          '&:hover': {
            filter: 'brightness(75%)',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
        },
      },
    },
  },
  palette: {
    primary: {
      contrastText: 'white',
      dark: cyan[800],
      light: cyan[100],
      main: cyan[500],
    },
    secondary: {
      contrastText: 'white',
      dark: teal[800],
      light: teal[100],
      main: teal[500],
    },
  },
  typography: {
    body1: {
      fontFamily: fontFamilyPrimary,
      marginBottom: '0.5rem',
      marginTop: '0.5rem',
    },
    button: {
      textTransform: 'none',
    },
    caption: {
      fontFamily: fontFamilyHeadings,
      fontSize: '1em',
    },
    fontFamily: fontFamilyPrimary,
    h1: {
      fontFamily: fontFamilyHeadings,
      fontSize: '3.2em',
      marginBottom: '0.8em',
      marginTop: '1.6em',
    },
    h2: {
      fontFamily: fontFamilyHeadings,
      fontSize: '2.8em',
      marginBottom: '0.7em',
      marginTop: '1.4em',
    },
    h3: {
      fontFamily: fontFamilyHeadings,
      fontSize: '2.24em',
      marginBottom: '0.56em',
      marginTop: '1.12em',
    },
    h4: {
      fontFamily: fontFamilyHeadings,
      fontSize: '2em',
      marginBottom: '0.5em',
      marginTop: '1em',
    },
    h5: {
      fontFamily: fontFamilyHeadings,
      fontSize: '1.6em',
      marginBottom: '0.4em',
      marginTop: '0.8em',
    },
    h6: {
      fontFamily: fontFamilyHeadings,
      fontSize: '1.2em',
      marginBottom: '0.3em',
      marginTop: '0.6em',
    },
    subtitle1: {
      fontFamily: fontFamilyHeadings,
      textTransform: 'uppercase',
    },
    subtitle2: {
      fontSize: '0.85em',
      opacity: '50%',
    },
  },
}
