import { ButtonEx, ButtonExProps } from '@xylabs/sdk-react'
import React from 'react'

import { hoverize } from '../hoverize'

interface Props extends ButtonExProps {
  hover?: number
  hoverTime?: number
}

export const PillButton: React.FC<Props> = ({ hover = 1.1, hoverTime = 0.3, style, ...props }) => {
  return <ButtonEx className={hoverize(hover, hoverTime)} style={{ borderRadius: 200, ...style }} {...props} />
}
