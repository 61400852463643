import { Box, Container, Typography, useTheme } from '@mui/material'
import { FlexRow } from '@xylabs/sdk-react'
import React, { useState } from 'react'

import { appUrl } from '../../appUrl'
import { PillButton } from '../../Components'
import { SubscribeForm } from '../SubscribeForm'

export const Title: React.FC = () => {
  const theme = useTheme()
  const [subscribeFormOpen, setSubscribeFormOpen] = useState(false)
  return (
    <Container style={{ marginBottom: 'auto', marginTop: 'auto' }}>
      <Box padding={4}>
        <Typography
          align="center"
          style={{ color: 'white', textShadow: '0em 0em .2em black' }}
          data-aos="fade-down"
          data-aos-duration="1200"
          data-aos-easing="ease"
          variant="h2"
        >
          Stake &amp; control the world of XYO
        </Typography>
      </Box>
      <Box padding={4}>
        <Typography
          align="center"
          data-aos="fade"
          data-aos-duration="1200"
          data-aos-easing="ease"
          variant="h4"
          style={{ color: theme.palette.secondary.light, textShadow: '0em 0em .2em black' }}
        >
          Own your piece of XYO&apos;s Decentralized Digital World&trade;, trade your pieces freely on the cryptoeconomic market.
        </Typography>
      </Box>
      <FlexRow data-aos="fade" data-aos-duration="1200" data-aos-easing="ease" flexWrap="wrap">
        <PillButton color="primary" variant="contained" href={appUrl('auctions')} margin={2}>
          current&nbsp;auctions
        </PillButton>
        <PillButton color="primary" variant="contained" href={appUrl('rules')} margin={2}>
          read&nbsp;the&nbsp;rules
        </PillButton>
        <PillButton color="primary" variant="contained" to="/tokens" margin={2}>
          get&nbsp;xyo&nbsp;tokens
        </PillButton>
      </FlexRow>
      <SubscribeForm onClose={() => setSubscribeFormOpen(false)} open={subscribeFormOpen} />
    </Container>
  )
}
