import { Grid } from '@mui/material'
import React from 'react'

import { HolderCard } from './Card'
import coinImage from './img/coin_card.svg'
import techImage from './img/foundation_card.svg'
import hodlImage from './img/xyo_card.svg'

export const Cards: React.FC = () => {
  return (
    <Grid container spacing={4}>
      <Grid xs={12} md={4} data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000" item>
        <HolderCard
          alt="COIN"
          caption="Geoclaiming changed COIN forever, but what if you could geoclaim forever? What if your ownership had
            other benefits as a COIN user? Now it does, and that ownership can cross platforms. XYO World allows
            you to stake your claim not only in COIN, but in any system built using XYO World."
          cta="Coming Soon: Take COIN to the next level."
          image={coinImage}
          subtitle="If you're serious about COIN, meet XYO World."
          title="COIN Users"
        />
      </Grid>
      <Grid xs={12} md={4} data-aos="fade-up" data-aos-delay="800" data-aos-duration="1000" item>
        <HolderCard
          alt="XYO"
          caption="Whether you're an original member of the Founding Holder Registry, or you've simply saved your COIN
            earnings and you're looking for ways to grow your assets, XYO World represents a new opportunity
            designed to help fulfill the promise of the XYO Token and XYO Network."
          cta="Coming Soon: Make your XYO work for you."
          image={hodlImage}
          subtitle="Use and grow your XYO."
          title="XYO Token Holders"
        />
      </Grid>
      <Grid xs={12} md={4} data-aos="fade-up" data-aos-delay="1200" data-aos-duration="1000" item>
        <HolderCard
          alt="XYO Foundation"
          caption="If you've been eagerly anticipating the next evolution of XYO Network, the wait
            is over. Our greatest supporters, XYO Geohackers like you, now have more power on
            XYO Network than ever before. Predictive staking data will now join absolute bound witness data."
          cta="Coming Soon: Connect your XYO World."
          image={techImage}
          subtitle="Not a developer? Not a problem."
          title="XYO Geohackers"
        />
      </Grid>
    </Grid>
  )
}
