import { Card, CardContent, Typography } from '@mui/material'
import cyan from '@mui/material/colors/cyan'
import teal from '@mui/material/colors/teal'
import React from 'react'

interface CardProps {
  caption: string
  title: string
}

const Box1: React.FC<CardProps> = (props) => {
  const { title, caption } = props
  return (
    <Card style={{ height: 320 }}>
      <CardContent style={{ backgroundColor: cyan[800], color: cyan[200], height: '100%' }}>
        <Typography variant="h5">{title}</Typography>
        <Typography>{caption}</Typography>
      </CardContent>
    </Card>
  )
}

const Box2: React.FC<CardProps> = (props) => {
  const { title, caption } = props
  return (
    <Card style={{ height: 320 }}>
      <CardContent style={{ backgroundColor: teal[100], color: teal[700], height: '100%' }}>
        <Typography variant="h5">{title}</Typography>
        <Typography>{caption}</Typography>
      </CardContent>
    </Card>
  )
}

const Box3: React.FC<CardProps> = (props) => {
  const { title, caption } = props
  return (
    <Card style={{ height: 320 }}>
      <CardContent style={{ backgroundColor: cyan[100], color: cyan[600], height: '100%' }}>
        <Typography variant="h5">{title}</Typography>
        <Typography>{caption}</Typography>
      </CardContent>
    </Card>
  )
}

const Box4: React.FC<CardProps> = (props) => {
  const { title, caption } = props
  return (
    <Card style={{ height: 320 }}>
      <CardContent style={{ backgroundColor: teal[500], color: teal[100], height: '100%' }}>
        <Typography variant="h5">{title}</Typography>
        <Typography>{caption}</Typography>
      </CardContent>
    </Card>
  )
}

const BoxBlank: React.FC = () => {
  return (
    <Card style={{ height: 320 }}>
      <CardContent style={{ backgroundColor: cyan[50], height: '100%' }}>&nbsp;</CardContent>
    </Card>
  )
}

export { Box1, Box2, Box3, Box4, BoxBlank }
