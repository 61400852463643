import { Box, Container, Link, Typography } from '@mui/material'
import { FlexCol, FlexRow } from '@xylabs/sdk-react'
import React, { useState } from 'react'

import { Footer, PillButton } from '../../Components'
import { BasePage } from '../BasePage'
import { ImageFlexBox } from '../ImageFlexBox'
import { SubscribeForm } from '../SubscribeForm'
import { FadeBlocks } from './FadeBlocks'
import { FooterCTA } from './FooterCTA'
import angleImage from './img/angle.svg'
import cyanHexImage from './img/cyan_hex.svg'
import doubleWaveImage from './img/double_hex_wave.svg'
import hexLayer1Image from './img/hex_layer1.svg'
import hexLayer2Image from './img/hex_layer2.svg'
import hexLayer3Image from './img/hex_layer3.svg'
import hexagonsImage from './img/hexagons.svg'
import waveHexImage from './img/wave_hex.svg'
import whiteHexImage from './img/white_hex.svg'
import { Laptop } from './Laptop'
import { Title } from './Title'

export const HomePage: React.FC = () => {
  const [subscribeFormOpen, setSubscribeFormOpen] = useState(false)
  return (
    <BasePage transparent>
      <ImageFlexBox videoSrc="/video/home-hero.mp4">
        <Title />
      </ImageFlexBox>
      <FlexCol alignItems="stretch" data-aos="fade-up">
        <Box
          style={{
            backgroundImage: `url(${hexagonsImage})`,
            backgroundSize: 'cover',
            height: 300,
            overflowY: 'hidden',
            position: 'relative',
            top: '2px',
            width: '100%',
          }}
        />
        <Box bgcolor="white">
          <Box sx={{ smDown: { display: 'none' } }} height={100} width={50} />
          <Box
            style={{
              backgroundImage: `url(${waveHexImage})`,
              backgroundPositionY: 'bottom',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              overflow: 'hidden',
              paddingBottom: '3em',
              position: 'relative',
              top: '3px',
            }}
          >
            <FlexRow component={Container} flexWrap="wrap">
              <FlexCol data-aos="fade-right">
                <Laptop />
                <FlexRow>
                  <PillButton color="primary" href="/start" variant="contained">
                    learn&nbsp;how&nbsp;it&nbsp;works
                  </PillButton>
                </FlexRow>
              </FlexCol>
              <FlexCol data-aos="fade-left">
                <Typography
                  style={{
                    fontFamily: 'Rock Salt',
                    letterSpacing: '0.05rem',
                    lineHeight: '50pt',
                  }}
                  variant="h5"
                >
                  &quot;We the people of the reality in which we live, demand the right of data sovereignty, data certainty, and data freedom.&quot;
                </Typography>
                <Typography align="right" variant="h5">
                  – Arie Trouw
                </Typography>
                <Typography align="right" variant="h6">
                  <Link color="textSecondary" href="https://arietrouw.com/" target="_blank">
                    XY Founder &amp; CEO
                  </Link>
                </Typography>
              </FlexCol>
            </FlexRow>
          </Box>
          <Box
            style={{
              backgroundColor: '#80deea',
              backgroundImage: `url(${hexLayer3Image})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              height: 300,
            }}
          >
            <Box
              style={{
                backgroundImage: `url(${hexLayer1Image})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                height: 300,
                position: 'relative',
                top: -60,
              }}
              data-aos="fade-up"
            />
            <Box
              style={{
                backgroundImage: `url(${hexLayer2Image})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                height: 300,
                position: 'relative',
                top: -210,
              }}
              data-aos="fade-down"
            />
          </Box>
          <Box
            style={{
              backgroundColor: '#e0f2f1',
              backgroundImage: `url(${doubleWaveImage})`,
              backgroundPositionY: 'bottom',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
            }}
          >
            <FadeBlocks />
          </Box>
          <Box
            style={{
              backgroundImage: `url(${angleImage})`,
              backgroundPosition: 'top',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              marginLeft: 'auto',
              marginRight: 'auto',
              minHeight: '10vh',
              position: 'relative',
              top: -2,
              width: '100%',
            }}
          >
            <Box
              style={{
                backgroundImage: `url(${whiteHexImage})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                display: 'flex',
                marginBottom: '10vh',
                marginLeft: 'auto',
                marginRight: 'auto',
                minHeight: '40vh',
                width: '80%',
              }}
              data-aos="flip-down"
            >
              <Box
                style={{
                  backgroundImage: `url(${cyanHexImage})`,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  display: 'flex',
                  height: '30vh',
                  marginTop: 'auto',
                  width: '100%',
                }}
                data-aos="flip-down"
                data-aos-delay="200"
              >
                <FooterCTA />
              </Box>
            </Box>
          </Box>
          <SubscribeForm onClose={() => setSubscribeFormOpen(false)} open={subscribeFormOpen} />
          <Footer />
        </Box>
      </FlexCol>
    </BasePage>
  )
}
