import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material'
import React from 'react'

import { PillButton } from '../Components'

interface SubscribeFormProps {
  onClose?: () => void
  open?: boolean
}

export const SubscribeForm: React.FC<SubscribeFormProps> = (props) => {
  const { open = false, onClose } = props

  return (
    <Dialog aria-labelledby="form-dialog-title" disableScrollLock={true} onClose={onClose} open={open}>
      <DialogTitle id="form-dialog-title">
        <Typography data-drip-attribute="headline" variant="h2">
          XYO World Updates
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form action="https://www.getdrip.com/forms/269168904/submissions" data-drip-embedded-form="269168904" method="post" noValidate>
          <DialogContentText>
            Sign up for XYO World updates and get the latest news about how and when XYO World is expanded and improved. You can be the first to know
            every important development in XYO World.
          </DialogContentText>
          <Box data-drip-attribute="description" />
          <Box>
            <TextField fullWidth id="drip-email" label="Email Address" name="fields[email]" type="email" />
          </Box>
          <Box>
            <TextField id="drip-eu-consent-denied" name="fields[eu_consent]" type="hidden" value="denied" />
            <FormControlLabel
              control={<Checkbox id="drip-eu-consent" name="fields[eu_consent]" value="granted" />}
              label="By ticking this box, you confirm you&#39;re cool with us emailing you."
            />
          </Box>
          <Box>
            <input name="fields[eu_consent_message]" type="hidden" value="By ticking this box, you confirm you&#39;re cool with us emailing you." />
          </Box>
          <Box aria-hidden="true" style={{ display: 'none' }}>
            <label>
              {' '}
              Website
              <input id="website" name="website" type="text" {...{ autoComplete: 'false', tabIndex: -1 }} value="" />
            </label>
          </Box>
          <DialogActions>
            <PillButton color="inherit" onClick={onClose} variant="contained">
              Cancel
            </PillButton>
            <PillButton color="primary" data-drip-attribute="sign-up-button" type="submit" value="Sign Up" variant="contained">
              Register
            </PillButton>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  )
}
