import { Box, Typography, useTheme } from '@mui/material'
import { FlexBoxProps, FlexCol } from '@xylabs/sdk-react'
import React, { useState } from 'react'

import { PillButton } from '../../Components'
import { SubscribeForm } from '../SubscribeForm'

export const Title: React.FC<FlexBoxProps> = (props) => {
  const theme = useTheme()
  const [subscribeFormOpen, setSubscribeFormOpen] = useState(false)
  return (
    <FlexCol {...props}>
      <Box padding={4}>
        <Typography
          align="center"
          data-aos="fade-down"
          data-aos-duration="1200"
          data-aos-easing="ease"
          style={{ color: 'white', textShadow: '0em 0em .2em black' }}
          variant="h2"
        >
          Let&apos;s find out where XYO World is headed
        </Typography>
      </Box>
      <Box padding={4}>
        <Typography
          align="center"
          data-aos="fade"
          data-aos-duration="1200"
          data-aos-easing="ease"
          variant="h4"
          style={{ color: theme.palette.primary.light, textShadow: '0em 0em .2em black' }}
        >
          Curious where XYO World is going next?
          <br />
          The XYO World roadmap has your answers.
        </Typography>
      </Box>
      <Box data-aos="fade" data-aos-duration="1200" data-aos-easing="ease" display="flex" justifyContent="center">
        <PillButton color="primary" onClick={() => setSubscribeFormOpen(true)} variant="contained">
          stay&nbsp;updated
        </PillButton>
      </Box>
      <SubscribeForm onClose={() => setSubscribeFormOpen(false)} open={subscribeFormOpen} />
    </FlexCol>
  )
}
