import { FlexRow } from '@xylabs/sdk-react'
import React from 'react'

import dtlaImage from './img/dtla.jpg'
import { Title } from './Title'

export const Hero: React.FC = () => {
  return (
    <FlexRow position="relative">
      <FlexRow position="absolute" minHeight="50vh">
        <img alt="Downtown Los Angeles" src={dtlaImage} height="100%" />
      </FlexRow>
      <Title />
    </FlexRow>
  )
}
