import { Box, Link, Theme, Typography, useTheme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { FlexBoxProps, FlexCol, FlexGrowRow, FlexRow } from '@xylabs/sdk-react'
import React from 'react'

import gridFadeBlack from './img/grid-fade-black.svg'

const useStyles = (theme: Theme, backgroundImage: string) => {
  return makeStyles(() => {
    return createStyles({
      image: {
        '&:hover': {
          backgroundPosition: 'center',
          backgroundSize: '115%',
          borderRadius: 0,
        },
        backgroundImage,
        backgroundPosition: 'center',
        backgroundSize: '100%',
        borderRadius: 300,
        transition: 'all 0.25s ease-in-out',
      },
      square: {
        '&:hover': {
          borderRadius: 0,
        },
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: 0,
        textAlign: 'center',
      },
      stakePlaces: {
        '&:hover': {
          backgroundColor: '#84ffffbf',
          backgroundImage: `url(${gridFadeBlack})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          borderRadius: 0,
          opacity: '100%',
        },
        backgroundColor: 'transparent',
        borderRadius: 300,
        fontSize: 0,
        opacity: '0%',
        transition: 'all 0.25s ease-in-out',
      },
      underline: {
        '& a': {
          color: theme.palette.primary.dark,
          cursor: 'pointer',
          transition: 'all 0.5s ease-in-out',
        },
        '& div': {
          height: 2,
          transition: 'all 0.5s ease-in-out',
          width: 0,
        },
        '&:hover': {
          '& a': {
            color: theme.palette.secondary.dark,
          },
          '& div': {
            backgroundColor: theme.palette.secondary.dark,
            width: '100%',
          },
        },
        display: 'flex',
        justifyContent: 'center',
      },
    })
  })
}

interface FadeBlockProps extends FlexBoxProps {
  caption: string
  hoverText: string
  image: string
  linkText: string
  target: string
  url: string
}

export const FadeBlock: React.FC<FadeBlockProps> = (props) => {
  const { caption, linkText, hoverText, image, url, target, ...rootProps } = props
  const classes = useStyles(useTheme(), image)()
  return (
    <FlexCol justifyContent="flex-start" {...rootProps}>
      <FlexRow className={[classes.square, classes.image].join(' ')}>
        <Box
          alignItems="center"
          className={[classes.square, classes.stakePlaces].join(' ')}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          height={300}
          width={300}
        >
          <Box>
            <Typography align="center" variant="h5">
              {hoverText}
            </Typography>
          </Box>
        </Box>
      </FlexRow>
      <FlexGrowRow padding={2} maxWidth={280}>
        <Typography align="left">{caption}</Typography>
      </FlexGrowRow>
      <FlexRow className={classes.underline}>
        <Link component="a" href={url} target={target} variant="h6">
          {linkText}
          <Box component="div" />
        </Link>
      </FlexRow>
    </FlexCol>
  )
}
