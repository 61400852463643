import { Card, CardActionArea, CardActions, CardContent, CardMedia, CardProps, Typography } from '@mui/material'
import React from 'react'

import { hoverize } from '../../hoverize'

interface Props extends CardProps {
  action: string
  alt: string
  caption: string
  image: string
  subtitle: string
  title: string
}

export const DevCard: React.FC<Props> = ({ style, title, subtitle, caption, image, alt, action, ...props }) => {
  return (
    <Card className={hoverize()} style={{ height: '100%', ...style }} {...props}>
      <CardActionArea style={{ height: '100%' }}>
        <CardMedia alt={alt} component="img" image={image} />
        <CardContent style={{ height: '100%' }}>
          <Typography variant="h5">{title}</Typography>
          <Typography variant="subtitle2">{subtitle}</Typography>
          <Typography style={{ marginTop: 25 }}>{caption}</Typography>
          <CardActions>
            <Typography variant="h6">
              Coming Soon: <br />
              {action}
            </Typography>
          </CardActions>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
