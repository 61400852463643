import { Box, Card, CardActions, CardContent, CardProps, Link, Typography } from '@mui/material'
import React from 'react'

import { PillButton } from '../../Components'
import coinVerticalImage from './img/coin_card.svg'
import coinImage from './img/coin_left_tab.svg'

export const Coin: React.FC<CardProps> = (props) => {
  return (
    <Card
      sx={{
        backgroundImage: { sm: `url(${coinImage})`, xs: `url(${coinVerticalImage})` },
        backgroundPosition: { sm: 'left', xs: 'top' },
        backgroundSize: { xs: 'stretch' },
        paddingLeft: { sm: '40%' },
        paddingTop: { sm: 0, xs: '50%' },
      }}
      style={{
        backgroundRepeat: 'no-repeat',
      }}
      {...props}
    >
      <CardContent>
        <Typography variant="h3">Want to join in on XYO World for free?</Typography>
        <Typography variant="h6">
          The easy way to get free XYO is with the COIN app. The app is free to download and use. Premium options are available for those who want to
          get more from COIN and from XYO World.
        </Typography>
        <CardActions>
          <Box>
            <Link href="https://coinapp.co/" target="_blank">
              <PillButton color="primary" style={{ width: '100%' }} variant="contained">
                get COIN
              </PillButton>
            </Link>
          </Box>
        </CardActions>
      </CardContent>
    </Card>
  )
}
