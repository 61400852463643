import { Facebook, Instagram, LinkedIn, Reddit, Telegram, Twitter, YouTube } from '@mui/icons-material'
import { Box, BoxProps, Container, Link, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { appUrl } from '../appUrl'
import { SpecialThanks } from './Thanks'

interface SocialLinksProps extends BoxProps {
  align?: 'start' | 'center' | 'end'
}

const SocialLinks: React.FC<SocialLinksProps> = (props) => {
  const { align = 'start', ...rootProps } = props
  const [specialThanksOpen, setSpecialThanksOpen] = useState(false)
  return (
    <Box display="flex" flexDirection="column" padding={2} textAlign={align} {...rootProps}>
      <Typography component={Box} padding={1} variant="subtitle1">
        XYO World
      </Typography>
      <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent={align} padding={1}>
        <Box paddingRight={1}>
          <Link href="https://business.facebook.com/OfficialXYO/" target="_blank" variant="body1">
            <Facebook />
          </Link>
        </Box>
        <Box paddingRight={1}>
          <Link href="https://twitter.com/OfficialXYO" target="_blank" variant="body1">
            <Twitter />
          </Link>
        </Box>
        <Box paddingRight={1}>
          <Link href="https://www.instagram.com/officialxyo/" target="_blank" variant="body1">
            <Instagram />
          </Link>
        </Box>
        <Box paddingRight={1}>
          <Link href="https://t.me/xyonetwork" target="_blank" variant="body1">
            <Telegram />
          </Link>
        </Box>
        <Box paddingRight={1}>
          <Link href="https://www.reddit.com/r/XYONetwork/" target="_blank" variant="body1">
            <Reddit />
          </Link>
        </Box>
        <Box paddingRight={1}>
          <Link href="https://www.youtube.com/channel/UCyZDqb9pgntVHJVt1pxXtsw" target="_blank" variant="body1">
            <YouTube />
          </Link>
        </Box>
        <Box paddingRight={1}>
          <Link href="https://www.linkedin.com/company/officialxyo/" target="_blank" variant="body1">
            <LinkedIn />
          </Link>
        </Box>
        <Box display="block" style={{ width: '100%' }}>
          <Link component="button" onClick={() => setSpecialThanksOpen(true)}>
            Special Thanks
          </Link>
        </Box>
      </Box>
      <SpecialThanks onClose={() => setSpecialThanksOpen(false)} open={specialThanksOpen} />
    </Box>
  )
}

const GetStarted: React.FC = () => (
  <Box display="flex" flexDirection="column" flexGrow={0} padding={2} textAlign="start">
    <Typography component={Box} padding={1} variant="subtitle1">
      Get Started
    </Typography>
    <Box padding={1}>
      <Link component={RouterLink} to="/start" variant="body1">
        Quick Start
      </Link>
    </Box>
    <Box padding={1}>
      <Link href="https://www.youtube.com/playlist?list=PLr-mGswEFd0Du7nXWB4lc7_m_pSU4r3_L" target="_blank" variant="body1">
        Video Guides
      </Link>
    </Box>
    <Box padding={1}>
      <Link href="https://support.xy.company/hc/en-us/sections/360008833073" target="_blank" variant="body1">
        Help Center
      </Link>
    </Box>
    <Box padding={1}>
      <Link href="https://support.xy.company/hc/en-us/requests/new" target="_blank" variant="body1">
        Support
      </Link>
    </Box>
  </Box>
)

const Developers: React.FC = () => (
  <Box display="flex" flexDirection="column" flexGrow={0} padding={2} textAlign="start">
    <Typography component={Box} padding={1} variant="subtitle1">
      Developers
    </Typography>
    <Box padding={1}>
      <Link href="https://developers.xyo.network/docs/en/open-source/" target="_blank" variant="body1">
        XYO Open Source
      </Link>
    </Box>
    <Box padding={1}>
      <Link href="https://developers.xyo.network/docs/en/sdk-guide/" target="_blank" variant="body1">
        SDKs
      </Link>
    </Box>
    <Box padding={1}>
      <Link href="https://developers.xyo.network/docs/en/dapploy/" target="_blank" variant="body1">
        Dapps
      </Link>
    </Box>
    <Box padding={1}>
      <Link href="https://developers.xyo.network/" target="_blank" variant="body1">
        Documentation
      </Link>
    </Box>
  </Box>
)

const XyoTokens: React.FC = () => (
  <Box display="flex" flexDirection="column" flexGrow={0} padding={2} textAlign="start">
    <Typography component={Box} padding={1} variant="subtitle1">
      XYO Tokens
    </Typography>
    <Box padding={1}>
      <Link href="https://support.xy.company/hc/en-us/articles/360052532054" target="_blank" variant="body1">
        XYOW NFT
      </Link>
    </Box>
    <Box padding={1}>
      <Link href="/tokens" variant="body1">
        XYO Tokens
      </Link>
    </Box>
    <Box padding={1}>
      <Link href="https://xyo.network/fhr/" target="_blank" variant="body1">
        FHR Benefits
      </Link>
    </Box>
    <Box padding={1}>
      <Link href={appUrl('rules')} variant="body1">
        Minting Rules
      </Link>
    </Box>
  </Box>
)

const Copyright: React.FC = () => (
  <Box display="block" flexGrow={0} marginBottom={2} marginTop={2} textAlign="center" width={'100%'}>
    <Typography component={Box} padding={1} variant="subtitle2">
      Copyright &copy; 2021{' '}
      <Link color="inherit" href="https://xylabs.com/" target="_blank">
        XY Labs
      </Link>{' '}
      &amp;{' '}
      <Link color="inherit" href="https://xyo.network/" target="_blank">
        XYO Foundation
      </Link>{' '}
      |{' '}
      <Link color="inherit" href="https://xylabs.com/privacy/" target="_blank">
        Privacy Policy
      </Link>{' '}
      |{' '}
      <Link color="inherit" href="https://xylabs.com/terms/" target="_blank">
        Terms of Service
      </Link>{' '}
      |{' '}
      <Link color="inherit" href="/adpolicy">
        Ad Policy
      </Link>
    </Typography>
  </Box>
)

export const Footer: React.FC = () => {
  return (
    <Container>
      <SocialLinks sx={{ mdUp: { display: 'none' } }} align="center" />
      <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="space-between">
        <SocialLinks sx={{ smDown: { display: 'none' } }} />
        <GetStarted />
        <Developers />
        <XyoTokens />
        <Copyright />
      </Box>
    </Container>
  )
}
