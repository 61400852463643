import { Typography, useTheme } from '@mui/material'
import { FlexBoxProps, FlexCol, FlexRow } from '@xylabs/sdk-react'
import React, { useState } from 'react'

import { PillButton } from '../../Components'
import { SubscribeForm } from '../SubscribeForm'

export const Title: React.FC<FlexBoxProps> = (props) => {
  const theme = useTheme()
  const [subscribeFormOpen, setSubscribeFormOpen] = useState(false)
  return (
    <FlexCol {...props}>
      <FlexRow padding={4}>
        <Typography
          align="center"
          color="white"
          style={{ textShadow: '0em 0em .2em black' }}
          data-aos="fade-down"
          data-aos-duration="1200"
          data-aos-easing="ease"
          variant="h2"
        >
          Get your rewards. Take over the XYO World.
        </Typography>
      </FlexRow>
      <FlexRow padding={4}>
        <Typography
          align="center"
          color={theme.palette.primary.light}
          style={{ textShadow: '0em 0em .2em black' }}
          data-aos="fade"
          data-aos-duration="1200"
          data-aos-easing="ease"
          variant="h4"
        >
          Own a cross-platform piece of the XYO Network.
          <br />
          Leverage your piece to get the rewards you deserve.
        </Typography>
      </FlexRow>
      <FlexRow data-aos="fade" data-aos-duration="1200" data-aos-easing="ease" display="flex" justifyContent="center">
        <PillButton color="primary" onClick={() => setSubscribeFormOpen(true)} variant="contained">
          stay&nbsp;ahead
        </PillButton>
      </FlexRow>
      <SubscribeForm onClose={() => setSubscribeFormOpen(false)} open={subscribeFormOpen} />
    </FlexCol>
  )
}
