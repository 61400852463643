import SecretIcon from '@mui/icons-material/LocationSearching'
import { Link, Typography } from '@mui/material'
import { FlexCol, FlexRow } from '@xylabs/sdk-react'
import React, { useState } from 'react'

import { hoverize } from '../../hoverize'
import cbImage from './img/coinbase.png'
import mmImage from './img/metamask.svg'
import { SubscribeForm } from './QAWallets'

export const Wallets: React.FC = () => {
  const [subscribeFormOpen, setSubscribeFormOpen] = useState(false)
  return (
    <>
      <FlexCol data-aos="fade-up">
        <Typography variant="h3">
          Officially Supported Web3 Wallets
          <Link
            style={{
              color: '#ffffff',
              marginLeft: '20px',
              opacity: '20%',
              transitionDuration: '0.5s',
              transitionProperty: 'all',
              transitionTimingFunction: 'ease-in-out',
            }}
            onClick={() => setSubscribeFormOpen(true)}
          >
            <SecretIcon />
          </Link>
        </Typography>
        <Typography variant="h6">Looking for a great Ethereum token wallet to use with XYO World? Here are our recommendations.</Typography>
        <FlexRow>
          <Link className={hoverize(1.025, 2)} href="https://metamask.io/" target="_blank">
            <img alt="MetaMask" height={300} src={mmImage} />
          </Link>
          <Link className={hoverize(1.025, 2)} href="https://www.coinbase.com/mobile" target="_blank">
            <img alt="MetaMask" height={300} src={cbImage} />
          </Link>
        </FlexRow>
      </FlexCol>
      <SubscribeForm onClose={() => setSubscribeFormOpen(false)} open={subscribeFormOpen} />
    </>
  )
}
