import { Box, Card, CardActions, CardContent, CardMedia, CardProps, Link, Typography } from '@mui/material'
import React from 'react'

import { PillButton } from '../../Components'

interface Props extends CardProps {
  alt: string
  button: string
  caption: string
  image: string
  link: string
  subtitle: string
  target: string
  title: string
}

export const HolderCard: React.FC<Props> = (props) => {
  const { title, subtitle, button, caption, image, alt, link, target } = props
  return (
    <Card>
      <CardMedia alt={alt} component="img" image={image} />
      <CardContent>
        <Box>
          <Typography variant="h5">{title}</Typography>
          <Typography variant="subtitle2">{subtitle}</Typography>
        </Box>
        <Typography height="20vh" minHeight={170} marginTop={4}>
          {caption}
        </Typography>
      </CardContent>
      <CardActions>
        <Box marginTop={2} style={{ marginLeft: 'auto' }}>
          <Link href={link} target={target}>
            <PillButton color="primary" style={{ width: '90%' }} variant="contained">
              {button}
            </PillButton>
          </Link>
        </Box>
      </CardActions>
    </Card>
  )
}
