import { Grid, GridProps } from '@mui/material'
import React from 'react'

import { appUrl } from '../../appUrl'
import { HolderCard } from './Card'
import geotokenImage from './img/geotokens_card.svg'
import quadtileImage from './img/quadtiles_card.svg'
import xyoImage from './img/xyo_card.svg'

export const Cards: React.FC<GridProps> = (props) => {
  return (
    <Grid container spacing={4} {...props}>
      <Grid xs={12} md={4} item>
        <HolderCard
          alt="Quadtiles"
          button="xyo&nbsp;world&nbsp;map"
          caption="Quadtiles divide the world into four squares on a two-dimensional map, each of which is then divided into four squares, and so on, up to 124 levels of granularity. These quadtiles are mapped in XYO World and used to provide data for apps built using XYO World."
          image={quadtileImage}
          link={appUrl('map')}
          subtitle="Quadtiles are the digital real estate of XYO World."
          target="_blank"
          title="The Object"
        />
      </Grid>
      <Grid xs={12} md={4} item>
        <HolderCard
          alt="Geotokens"
          button="find&nbsp;geotokens"
          caption="Geotokens are an Ethereum blockchain ERC-721 non-fungible token, meaning that every single one is unique. They're generated and auctioned automatically, they can store data and other tokens, and each one is linked permanently to a quadtile in the XYO World system. Owning the geotoken means owning and staking its quadtile."
          image={geotokenImage}
          link={appUrl('auctions')}
          subtitle="Geotokens are the blockchain deeds to quadtiles."
          target="_blank"
          title="The Stake"
        />
      </Grid>
      <Grid xs={12} md={4} item>
        <HolderCard
          alt="XYO Tokens"
          button="get&nbsp;xyo&nbsp;tokens"
          caption="XYO Tokens (also known simply as XYO) are an ERC-20 Ethereum token, first introduced in May of 2018. Utility tokens like XYO are meant to be more than digital currency – XYO has function. On XYO Network, XYO is used to trade location data. In XYO World, it's used to stake real-world locations."
          image={xyoImage}
          link="/tokens"
          subtitle="The system runs with the token that started it all."
          target="_self"
          title="The Fuel"
        />
      </Grid>
    </Grid>
  )
}
