// eslint-disable-next-line import/no-internal-modules
import 'pure-react-carousel/dist/react-carousel.es.css'

import ArrowRightIcon from '@mui/icons-material/FastForward'
import ArrowLeftIcon from '@mui/icons-material/FastRewind'
import { Link, Typography, useTheme } from '@mui/material'
import { FlexCol, FlexRow } from '@xylabs/sdk-react'
import { ButtonBack, ButtonNext, CarouselProvider, Slide, SlideProps, Slider } from 'pure-react-carousel'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import gridImage from './img/grid.svg'
import laptopImage from './img/notebook.png'

const CustomSlide: React.FC<SlideProps> = ({ children, ...props }) => {
  return (
    <Slide style={{ display: 'flex', height: 283, justifyContent: 'center', overflow: 'hidden', width: 500 }} {...props}>
      <FlexRow height={283} overflow="hidden" width={500}>
        <Typography
          style={{
            marginTop: 'auto',
            paddingBottom: 15,
            textAlign: 'left',
            textShadow: '1px 1px 6px #000',
            width: '90%',
          }}
          variant="h6"
        >
          {children}
        </Typography>
      </FlexRow>
    </Slide>
  )
}

export const Laptop: React.FC = () => {
  const theme = useTheme()

  return (
    <CarouselProvider infinite={true} interval={5000} isPlaying={true} naturalSlideHeight={125} naturalSlideWidth={100} totalSlides={3}>
      <FlexCol
        alignItems="stretch"
        sx={{ backgroundImage: { md: `url(${laptopImage})` } }}
        style={{
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          color: theme.palette.common.white,
          height: 460,
          position: 'relative',
          width: 700,
        }}
      >
        <video autoPlay style={{ height: 285, left: 96, position: 'absolute', top: 21 }} loop muted>
          <source src="/video/screen-sim.mp4" type="video/mp4" />
        </video>
        <FlexCol position="absolute" top={21} alignItems="stretch" left={96}>
          <Slider
            style={{
              backgroundColor: '#00000066',
              backgroundImage: `url(${gridImage})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              border: '1px solid #ffffff66',
              height: 285,
              position: 'relative',
              width: 505,
            }}
          >
            <CustomSlide index={0}>
              XYO World gamifies network staking, making it{' '}
              <Link component={RouterLink} to="/start">
                more accessible than ever
              </Link>
              . The resulting data has real world applications, making it easy and rewarding to build consensus.
            </CustomSlide>
            <CustomSlide index={1}>
              XYO World is backed by a human collective, predicting real patterns. It can{' '}
              <Link component={RouterLink} to="/developers">
                provide data that is informative
              </Link>{' '}
              above and beyond even the smartest, most advanced GPS map.
            </CustomSlide>
            <CustomSlide index={2}>
              And XYO World only uses the XYO Token for currency. Anyone can join in at no cost. It&apos;s consensus for everyone. Get started for
              free with the{' '}
              <Link href="https://coinapp.co/" target="_blank">
                COIN app today
              </Link>
              !
            </CustomSlide>
          </Slider>
          <FlexRow justifyContent="space-between" position="relative">
            <ButtonBack
              style={{
                background: 'transparent',
                border: 0,
                borderRadius: 5,
                color: 'gray',
                margin: 2,
                outline: 'none',
                paddingTop: 3,
                textAlign: 'left',
                width: 100,
              }}
            >
              <ArrowLeftIcon fontSize="large" />
            </ButtonBack>
            <ButtonNext
              style={{
                background: 'transparent',
                border: 0,
                borderRadius: 5,
                color: 'gray',
                margin: 2,
                outline: 'none',
                paddingTop: 3,
                textAlign: 'right',
                width: 100,
              }}
            >
              <ArrowRightIcon fontSize="large" />
            </ButtonNext>
          </FlexRow>
        </FlexCol>
      </FlexCol>
    </CarouselProvider>
  )
}
