import { Container, Link, Typography } from '@mui/material'
import { FlexCol, FlexRow } from '@xylabs/sdk-react'
import React, { useState } from 'react'

import { Footer } from '../../Components'
import { BasePage } from '../BasePage'
import { ImageFlexBox } from '../ImageFlexBox'
import { SubscribeForm } from '../SubscribeForm'
import { Cards } from './Cards'
import { Cards2 } from './Cards2'
import highlightHexImage from './img/hex-dots.svg'
import heroImage from './img/sequoia.jpg'
import topHexImage from './img/wave_hex.svg'
import { Title } from './Title'

export const RoadmapPage: React.FC = () => {
  const [subscribeFormOpen, setSubscribeFormOpen] = useState(false)
  return (
    <BasePage transparent>
      <ImageFlexBox alt="Sequoia National Park" src={heroImage}>
        <Title minHeight="70vh" />
      </ImageFlexBox>
      <FlexCol data-aos="fade-up" alignItems="stretch">
        <FlexRow
          style={{
            backgroundImage: `url(${topHexImage})`,
            backgroundSize: 'cover',
            height: 420,
            overflowY: 'hidden',
            width: '100%',
          }}
        />
        <FlexCol bgcolor="white" alignItems="stretch">
          <FlexRow
            style={{
              backgroundColor: '#fff',
              backgroundImage: `url(${highlightHexImage})`,
              backgroundPosition: 'bottom',
              backgroundSize: 'cover',
              minHeight: '50vh',
              overflow: 'hidden',
              paddingBottom: '3em',
              position: 'relative',
              top: 3,
            }}
          >
            <FlexCol alignItems="stretch">
              <FlexCol data-aos="fade-left" marginBottom={15}>
                <Typography variant="h2">XYO World Roadmap</Typography>
                <Typography variant="h3">Q4 2020 - Q1 2021</Typography>
              </FlexCol>
              <Container>
                <Cards />
              </Container>
              <FlexRow data-aos="fade-left" marginBottom={15}>
                <Typography variant="h3">Q2 2021 - Q4 2021 and Beyond</Typography>
              </FlexRow>
              <Container>
                <Cards2 />
              </Container>
              <FlexCol>
                <Typography variant="h4">Want to stay up to date?</Typography>
                <Typography variant="h6">
                  <Link onClick={() => setSubscribeFormOpen(true)} style={{ cursor: 'pointer' }}>
                    Sign up for the XYO newsletter{' '}
                  </Link>
                  or follow us on your favorite socials.
                </Typography>
              </FlexCol>
            </FlexCol>
          </FlexRow>
          <Footer />
        </FlexCol>
      </FlexCol>
      <SubscribeForm onClose={() => setSubscribeFormOpen(false)} open={subscribeFormOpen} />
    </BasePage>
  )
}
